<template>
  <div>
    <v-dialog
      :key="productDetailVisible"
      v-model="productDetailVisible"
      scrollable persistent
      width="960"
    >
      <v-card class="popup">
        <div class="popup-titbox _between">
          <v-card-title>
          <h3 class="page-title-bar">
            <i class="ico ico-product"></i>{{ $t("Product Detail") }}</h3>
          </v-card-title>
          <div class="text-right product-idNumber"><p>Product ID</p> {{ articleId ? articleId : '' }} </div>
        </div>
        <v-card-text>
        <!-- <div class="custom-scrollBox" style="max-height: 650px"> -->
          <div class="productInfo d-flex">
            <div class="list">
              <v-data-table
                :headers="pdHeaders"
                :items="pdItems"
                :page.sync="page"
                :hide-default-footer="true"
                item-key="uploadId"
                class="tbl-type01"
                :item-class="itemRowBackground"
              >
              <!-- @click:row="showProductDetailByUploadDate" -->
                <template v-slot:[`item.uploadDate`]="{ item }">
                {{ item.uploadDate ? getUTCtoLocal(item.uploadDate): '-' }}
              </template>
              </v-data-table>
            </div>

            <div class="product_detail" style="width: 80%; background-color: #f0f0f0">
              <table>
                <tr>
                  <th>
                    <div style="display: inline-flex; width: 30%; margin: 1.5%" v-for="(articleData, idx) in articleDataList" :key="idx">
                      <div>
                        <strong> {{ $t(articleData.key) }} </strong>
                        <v-text-field v-model="articleData.value" :placeholder="getPlaceholder(articleData.key)"
                          :disabled="idx < 1" class="form-input flat rounded-0" style="padding-top: 10px" outlined dense hide-details
                          clearable></v-text-field>
                      </div>
                    </div>
                    <div>
                      <v-col>
                        <strong> {{ $t('Mapped Template ID') }} </strong>
                        <v-textarea :auto-grow="false" :value="getTemplateData()"
                          class="form-input flat rounded-0" no-resize
                          style="padding-top: 10px" outlined dense hide-details readonly></v-textarea>
                      </v-col>
                    </div>
                    <div>
                      <v-col>
                        <strong> {{ $t('Assigned LCD') }} </strong>
                        <v-textarea :value="getAssignedData()" :auto-grow="false" readonly no-resize class="form-input flat rounded-0"
                          style="padding-top: 10px" outlined dense hide-details></v-textarea>
                      </v-col>
                    </div>
                  </th>
                </tr>
                <v-row no-gutters class="py-2">
                </v-row>
              </table>
            </div>
          </div>
        <!-- </div> -->
      </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="productDetailUpdateDisabled"
            @click="updateArticle"
            class="btn"
            text
            icon
          >
            {{ $t("Update") }}
          </v-btn>
          <v-btn @click="closeProductDetail" class="btn" text icon>
            {{ $t("Cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- for whitelist add -->
    <v-dialog v-model="dialog" width="400">
      <v-card class="popup add_store_popup" align="center" justify="center">
        <v-row>
          <v-col>
            <span v-text="errormessage"></span>
          </v-col>
        </v-row>
        <v-card-actions class="d-flex justify-center">
          <v-btn class="btn" text icon @click="addWhitelist">Yes</v-btn>
          <v-btn class="btn" text icon @click="dialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- for whitelist success -->
    <v-dialog v-model="assigndialog" width="400">
      <v-card class="popup add_store_popup" align="center" justify="center">
        <v-row>
          <v-col>
            <span v-text="messageText"></span>
          </v-col>
        </v-row>
        <v-card-actions class="d-flex justify-center">
          <v-btn class="btn" text icon @click="assignArticle(labelsToAssign[0])"
            >Yes</v-btn
          >
          <v-btn class="btn" text icon @click="assigndialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import dicamoApi from '@/lcd/plugins/dicamoApi'

export default {
  props: {
    store: Object,
    pdHeaders: Array,
    pdItems: Array,
    page: Number
  },
  data () {
    return {
      productDetailVisible: false,
      productList: [],
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      articleId: null,
      uploadId: null,
      initArticleDataList: [],
      articleDataList: [],
      articleDataKeyList: [],
      articleDataValueList: [],
      assignedLabels: [],
      labelsToAssign: [''],
      essentialProductData: {
        // keys: [],
        // names: []
        keys: ['ARTICLE_ID', 'ITEM_NAME', 'NFC_URL'],
        names: ['PRODUCT ID', 'DESCRIPTION', 'NFC URL']
      },
      productDetailUpdateDisabled: false,
      productAssignDisabled: false,
      updateDate: '',
      dialog: false,
      assigndialog: false,
      errormessage: '',
      LabelList: '',
      messageText: '',
      whiteListFetchType: 'ADD',
      StoreCode: ''
    }
  },
  computed: {},
  mounted () {
    EventBus.$on('showProductDetail', () => {
      this.productDetailVisible = true
    })
    EventBus.$on('showCurrArticleDataValues', (item) => {
      this.articleId = item.articleId
      this.uploadId = item.uploadId
      this.showCurrArticleDataValues()
      this.getAssignedLCD(item.articleId)
    })
    this.getArticleData()
    const params = { store: this.store.code }
    const config = { params: params }
    if (this.productionType === 'cloud') { params.company = this.$store.state.auth.user.company }
    this.$utils
      .callAxios(
        codes.requests.getArticleDataKeyList.method,
        codes.requests.getArticleDataKeyList.url,
        config
      )
      .then((res) => {
        this.articleDataList = this.getArticleDataListWithKeys(
          this.sortArticleDataKeys(res.data.articleDataKeyList)
        )
      })
    this.$store.dispatch('auth/getDisabledBtn', '3101').then((flag) => {
      this.productDetailUpdateDisabled = flag
    })
    this.$store.dispatch('auth/getDisabledBtn', '3101').then((flag) => {
      // 위 3103 코드는 추후 assign권한이 따로 생기면 바뀔 수 있음.
      this.productAssignDisabled = flag
    })
  },
  beforeDestroy () {
    EventBus.$off('showCurrArticleDataValues')
    EventBus.$off('showProductDetail')
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    getTemplateData () {
      let list = []
      if (this.productList.length > 0) {
        this.productList[0].lcd.forEach(item => {
          // list = list + item.template_name + '\n'
          list.push(item.template_name)
        })
      }
      list = [...new Set(list)]
      let str = ''
      list.forEach(item => {
        str += item + '\n'
      })
      return str
    },
    getAssignedData () {
      let list = []
      if (this.productList.length > 0) {
        this.productList[0].lcd.forEach(item => {
          // list = list + item.mac + '' + '\n'
          list.push(item.mac)
        })
      }
      list = [...new Set(list)]
      let str = ''
      list.forEach(item => {
        str += item + '\n'
      })
      return str
    },
    getAssignedLCD (articleId) {
      const form = new FormData()
      // console.log(articleId)

      form.append('company', this.$store.state.auth.user.company)
      form.append('store', this.$store.state.dataStore.selectedStore.code)
      form.append('type', 'product')
      form.append('product_ids', JSON.stringify([articleId]))

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getTemplateList.method,
          dicamoApi.requests.getTemplateList.url,
          form
        )
        .then(res => {
          // console.log(res.data.data)
          this.productList = res.data.data
          // this.mediaDataList = this.convertProductsDate(res.data.articleList)
          // this.totalProducts = this.products.length
          // this.pageInfoText = commons.getPageInfoText(res.headers)
          // this.totalPages = Number(res.headers['x-totalpages'])
          // this.selected = []
        })
        .catch(error => {
          this.productList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any medias. error: ${error}`)
        })
    },

    getArticleData () {
      // Get article data key list.
      const params = { store: this.store.code }
      const config = { params: params }
      params.company = this.$store.state.auth.user.company
      this.$utils
        .callAxios(
          codes.requests.getArticleDataKeyList.method,
          codes.requests.getArticleDataKeyList.url,
          config
        )
        .then(res => {
          console.log(res.data)
          this.articleDataList = this.getArticleDataListWithKeys(this.sortArticleDataKeys(res.data.articleDataKeyList))
          this.initArticleDataList = require('lodash').cloneDeep(this.articleDataList)
        })
    },
    getUTCtoLocal (date) {
      return commons.converToLocalTime(`${date} UTC`)
    },
    convertProductsDate (uploadDate) {
      return commons.convertDate(uploadDate)
    },
    itemRowBackground (item) {
      return item.uploadId === this.uploadId ? 'active-class' : ''
    },
    showProductDetailByUploadDate (item) {
      this.articleId = item.articleId
      this.uploadId = item.uploadId
      this.showCurrArticleDataValues()
    },
    handleLabelIdKeyUp (idx, e) {
      this.$nextTick(() => {
        // 한글, 알파벳 G~Z 특수문자 입력불가 ( 0~9 A~F 만 입력 가능) 소문자->대문자로 변경
        const labelIdFilter =
          /[ㄱ-ㅎㅏ-ㅣ가-힣g-zG-Z~!@#$%^&*()\-=+_';<>/.`:",[]?|{}]/gi
        e.target.value = e.target.value
          .toUpperCase()
          .replace(labelIdFilter, '')
        this.labelsToAssign[idx] = e.target.value
      })
      this.$forceUpdate()
    },
    getPlaceholder (key) {
      return this.$t(`Input the ${key}`)
    },
    closeProductDetail () {
      this.articleDataList = require('lodash').cloneDeep(this.initArticleDataList)
      this.labelsToAssign = ['']
      this.assignedLabels = []
      this.productDetailVisible = false
    },
    buildProduct (articleDataList) {
      const product = {}
      const articleData = {}
      for (const productData of articleDataList) {
        if (commons.isValidStr(productData.value)) {
          articleData[productData.key] = productData.value
        } else {
          articleData[productData.key] = null
        }
      }
      const essentialProductDataNames = this.essentialProductData.names
      for (const name of essentialProductDataNames) {
        delete articleData[name]
      }
      product.articleId = articleDataList[0].value
      product.articleName = articleDataList[1].value || ''
      product.nfcUrl = articleDataList[2].value
      product.data = articleData
      return product
    },
    updateArticle () {
      const productToUpdate = this.buildProduct(this.articleDataList)
      productToUpdate.articleId = this.articleId
      const isValidData = commons.isValidData({
        productId: productToUpdate.articleId
        // description: productToUpdate.articleName
      })
      if (!isValidData.result) {
        EventBus.$emit('messageAlert', this.$t(isValidData.msg))
        return
      }
      // const isValidData = commons.isValidData({
      //   productId: productToUpdate.articleId
      //   // description: productToUpdate.articleName
      // })
      // if (!isValidData.result) {
      //   EventBus.$emit('messageAlert', this.$t(isValidData.msg))
      //   return
      // }
      const productData = [productToUpdate]
      const params = { store: this.$store.state.dataStore.selectedStore.code, article: this.articleId }
      const config = { params }
      if (this.productionType === 'cloud') { params.company = this.$store.state.auth.user.company }
      this.$utils
        .callAxiosWithBody(
          codes.requests.updateArticle.method,
          codes.requests.updateArticle.url,
          productData,
          config
        )
        .then((res) => {
          const result = res.data
          EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          this.closeProductDetail()
          this.$emit('fireResetProduct')
        })
        .catch((error) => {
          const result = error.response.data
          EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          this.closeProductDetail()
        })
    },
    getAnArticleData (articleId) {
      EventBus.$emit('loadingOn')
      if (commons.isNull(articleId)) return
      const params = {
        store: this.$store.state.dataStore.selectedStore.code,
        article: articleId,
        uploadId: this.uploadId
      }
      const config = { params }
      if (this.productionType === 'cloud') { params.company = this.$store.state.auth.user.company }
      return this.$utils
        .callAxios(
          codes.requests.getAnArticle.method,
          codes.requests.getAnArticle.url,
          config
        )
        .then((res) => {
          return res.data
        })
        .catch((error) => {
          EventBus.$emit('loadingOff')
          console.debug(`Failed to get data of an article. ${error}`)
        })
    },
    setAssignedLabel (assignedLabels) {
      if (commons.isValidStr(assignedLabels)) {
        this.assignedLabels = assignedLabels
      }
    },
    async showCurrArticleDataValues () {
      // Re Initailaize
      for (const articleData of this.articleDataList) {
        articleData.value = ''
      }
      const res = await this.getAnArticleData(this.articleId)
      if (commons.isNull(res)) return
      this.setAssignedLabel(res.assignedLabel)
      const data = res.data
      if (commons.isNull(data)) return
      for (const articleData of this.articleDataList) {
        if (data[articleData.key] !== undefined) {
          articleData.value = data[articleData.key]
        } else {
          articleData.value = ''
          if (articleData.key === 'ARTICLE_ID' && articleData.value === '') {
            articleData.key = 'PRODUCT ID'
            articleData.value = res.articleId
            articleData.key = 'ARTICLE_ID'
            articleData.value = ''
          }
        }
      }
      EventBus.$emit('loadingOff')
      const essentialKeys = [
        { key: 'PRODUCT ID', value: res.articleId },
        { key: 'DESCRIPTION', value: res.articleName },
        { key: 'NFC URL', value: res.nfcUrl }
      ]
      for (const idx in essentialKeys) {
        if (!commons.isNull(essentialKeys[idx].value)) { this.articleDataList[idx] = essentialKeys[idx] }
      }
    },
    getArticleDataListWithKeys (articleDataKeyList) {
      const articleDataList = []
      for (const articleDataKey of articleDataKeyList) {
        const articleData = {}
        articleData.key = articleDataKey
        articleData.value = ''
        articleDataList.push(articleData)
      }
      return articleDataList
    },
    buildAssignList (articleId, labelToAssign) {
      const reqBody = {}
      const assignList = []
      const assignData = {}
      const articleIdList = []
      articleIdList.push(articleId)
      assignData.articleIdList = articleIdList
      assignData.labelCode = labelToAssign
      assignList.push(assignData)
      reqBody.assignList = assignList
      return reqBody
    },
    getTranslatedErrorMsg (error) {
      if (error.response === undefined) { return this.$t('Failed to assign the product to the label') }
      const data = error.response.data
      if (commons.matchErrorType(error, codes.errors.templateNotFound)) {
        const responseMessage = data.responseMessage.split(' ')
        const labelId = responseMessage.pop()
        const msg = this.$t(responseMessage.join(' '))
        return `${msg} ${labelId}`
      } else if (
        commons.matchErrorType(error, codes.errors.labelNotWhitelisted)
      ) {
        const responseMessage = data.responseMessage.split(' ')
        const labelId = responseMessage.shift()
        const storeCode = responseMessage.pop().replace('station', '')
        responseMessage.push('station')
        const msg = this.$t(responseMessage.join(' '))
        return `${labelId} ${msg} ${storeCode}`
      } else {
        return this.$t(data.responseMessage)
      }
      // else if (commons.matchErrorType(error, codes.errors.labelNotWhitelisted)) {

      // const responseMessage = data.responseMessage.split(' ')
      // const labelId = responseMessage.shift()
      // const storeCode = responseMessage.pop().replace('station', '')
      // responseMessage.push('station')
      // const msg = this.$t(responseMessage.join(' '))

      // return `${labelId} ${msg} ${storeCode}` + ',' + 'Do you want to add?'
      // }
    },
    assignArticle (labelToAssign) {
      // this.assigndialog = false

      const reqBody = this.buildAssignList(this.articleId, labelToAssign)
      const params = { store: this.$store.state.dataStore.selectedStore.code }
      const config = { params: params }
      if (this.productionType === 'cloud') { params.company = this.$store.state.auth.user.company }
      this.$utils
        .callAxiosWithBody(
          codes.requests.assignArticles.method,
          codes.requests.assignArticles.url,
          reqBody,
          config
        )
        .then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          commons.remove(this.labelsToAssign, labelToAssign)
          commons.add(this.assignedLabels, labelToAssign)
        })
        .catch((error) => {
          console.debug(`Could not assgin an article. error: ${error}`)
          // if (error.response.data.responseCode === '406') {
          //   const data = error.response.data
          //   const responseMessage = data.responseMessage.split(' ')
          //   const labelId = responseMessage.shift()
          //   this.LabelList = labelId
          //   const storeCode = responseMessage.pop().replace('station', '')
          //   responseMessage.push('station')
          //   const msg = this.$t(responseMessage.join(' '))
          //   this.errormessage = `${labelId} ${msg} ${storeCode}` + ',' + 'Do you want to add?'
          //   this.dialog = true
          // } else {
          //   this.dialog = false
          EventBus.$emit('messageAlert', this.getTranslatedErrorMsg(error))
          // }
        })
    },
    unassignArticle (assignedLabel) {
      const reqBody = { unAssignList: [assignedLabel] }
      const params = { store: this.$store.state.dataStore.selectedStore.code }
      const config = { params: params }
      if (this.productionType === 'cloud') { params.company = this.$store.state.auth.user.company }
      this.$utils
        .callAxiosWithBody(
          codes.requests.unassignArticles.method,
          codes.requests.unassignArticles.url,
          reqBody,
          config
        )
        .then((res) => {
          if (res.status === 200) {
            const msg = res.data.responseMessage.split(' ')
            const labelId = msg.pop()
            EventBus.$emit(
              'messageAlert',
              `${this.$t(msg.join(' '))} ${labelId}`
            )
            commons.remove(this.assignedLabels, assignedLabel)
          } else {
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          }
        })
        .catch((error) => {
          const result = error.response.data
          EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          console.debug(`Could not unassign an article. error: ${error}`)
        })
    },
    addNewLabelInput () {
      this.labelsToAssign.push('')
    },
    sortArticleDataKeys (articleDataKeys) {
      const essentialKeys = this.essentialProductData.keys
      articleDataKeys.unshift(...essentialKeys)
      return articleDataKeys
    },
    editArticleHeader (key, idx) {
      if (idx < 1) return `${key}*`
      return key
    },
    // add whitelist
    addWhitelist () {
      const params = {
        company: this.$store.state.auth.user.company,
        store: this.$store.state.dataStore.selectedStore.code
      }
      const config = { params }
      const reqBody = {
        whiteListEnable: 'Enable',
        divisionCode: String('0'),
        whiteListFetchType: 'ADD',
        labelCodeList: [this.LabelList]
      }
      this.$utils
        .callAxiosWithBody(
          codes.requests.updateWhitelist.method,
          codes.requests.updateWhitelist.url,
          reqBody,
          config
        )
        .then((res) => {
          const result = res.data
          if (this.whiteListFetchType === 'ADD') {
            this.dialog = false
            this.assigndialog = true
            this.messageText =
              this.$t('Label successfully registered in whitelist.') +
              '' +
              'Do you want to assign it?'
            // EventBus.$emit('messageAlert', this.$t('Labels(s) successfully registered'))
          } else {
            this.dialog = false
            this.assigndialog = false
            EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          }
        })
        .catch((error) => {
          console.log(error)
          EventBus.$emit(
            'messageAlert',
            this.$t('Failed to update the whitelist information')
          )
        })
    }
  }
}
</script>
  <style scoped>
  /* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
