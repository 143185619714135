const Pagenation = {
  components: {},
  props: {},
  data () {
    return {
      goToPageInput: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    goTenPageBackwards (page, goToPageCallBack) {
      console.log('pop')
      console.log(page, goToPageCallBack)
      if (!page) return
      page = Number(page) - 2
      console.log('page' + page)
      if (page < 1) return
      goToPageCallBack.call(this, page)
    },
    goTenPageForward (page, goToPageCallBack, totalPage) {
      console.log('iop')
      console.log(page, goToPageCallBack, totalPage)
      if (!page || !totalPage) return
      page = Number(page) + 2
      if (page > totalPage) return
      goToPageCallBack.call(this, page)
    },
    goToPage (page, goToPageCallBack, totalPage) {
      console.log('going')
      if (!page || !totalPage) return
      if (page > totalPage || page < 1) return
      goToPageCallBack.call(this, page)
    }
  }
}
export default Pagenation
