var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          key: _vm.productDetailVisible,
          attrs: { scrollable: "", persistent: "", width: "960" },
          model: {
            value: _vm.productDetailVisible,
            callback: function($$v) {
              _vm.productDetailVisible = $$v
            },
            expression: "productDetailVisible"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup" },
            [
              _c(
                "div",
                { staticClass: "popup-titbox _between" },
                [
                  _c("v-card-title", [
                    _c("h3", { staticClass: "page-title-bar" }, [
                      _c("i", { staticClass: "ico ico-product" }),
                      _vm._v(_vm._s(_vm.$t("Product Detail")))
                    ])
                  ]),
                  _c("div", { staticClass: "text-right product-idNumber" }, [
                    _c("p", [_vm._v("Product ID")]),
                    _vm._v(
                      " " + _vm._s(_vm.articleId ? _vm.articleId : "") + " "
                    )
                  ])
                ],
                1
              ),
              _c("v-card-text", [
                _c("div", { staticClass: "productInfo d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "list" },
                    [
                      _c("v-data-table", {
                        staticClass: "tbl-type01",
                        attrs: {
                          headers: _vm.pdHeaders,
                          items: _vm.pdItems,
                          page: _vm.page,
                          "hide-default-footer": true,
                          "item-key": "uploadId",
                          "item-class": _vm.itemRowBackground
                        },
                        on: {
                          "update:page": function($event) {
                            _vm.page = $event
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.uploadDate",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.uploadDate
                                          ? _vm.getUTCtoLocal(item.uploadDate)
                                          : "-"
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "product_detail",
                      staticStyle: {
                        width: "80%",
                        "background-color": "#f0f0f0"
                      }
                    },
                    [
                      _c(
                        "table",
                        [
                          _c("tr", [
                            _c(
                              "th",
                              [
                                _vm._l(_vm.articleDataList, function(
                                  articleData,
                                  idx
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: idx,
                                      staticStyle: {
                                        display: "inline-flex",
                                        width: "30%",
                                        margin: "1.5%"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(articleData.key)
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-text-field", {
                                            staticClass:
                                              "form-input flat rounded-0",
                                            staticStyle: {
                                              "padding-top": "10px"
                                            },
                                            attrs: {
                                              placeholder: _vm.getPlaceholder(
                                                articleData.key
                                              ),
                                              disabled: idx < 1,
                                              outlined: "",
                                              dense: "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: articleData.value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  articleData,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression: "articleData.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("Mapped Template ID")
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c("v-textarea", {
                                          staticClass:
                                            "form-input flat rounded-0",
                                          staticStyle: {
                                            "padding-top": "10px"
                                          },
                                          attrs: {
                                            "auto-grow": false,
                                            value: _vm.getTemplateData(),
                                            "no-resize": "",
                                            outlined: "",
                                            dense: "",
                                            "hide-details": "",
                                            readonly: ""
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("Assigned LCD")) +
                                              " "
                                          )
                                        ]),
                                        _c("v-textarea", {
                                          staticClass:
                                            "form-input flat rounded-0",
                                          staticStyle: {
                                            "padding-top": "10px"
                                          },
                                          attrs: {
                                            value: _vm.getAssignedData(),
                                            "auto-grow": false,
                                            readonly: "",
                                            "no-resize": "",
                                            outlined: "",
                                            dense: "",
                                            "hide-details": ""
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ]),
                          _c("v-row", {
                            staticClass: "py-2",
                            attrs: { "no-gutters": "" }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: {
                        disabled: _vm.productDetailUpdateDisabled,
                        text: "",
                        icon: ""
                      },
                      on: { click: _vm.updateArticle }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Update")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.closeProductDetail }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "400" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "popup add_store_popup",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.errormessage) }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.addWhitelist }
                    },
                    [_vm._v("Yes")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("No")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "400" },
          model: {
            value: _vm.assigndialog,
            callback: function($$v) {
              _vm.assigndialog = $$v
            },
            expression: "assigndialog"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "popup add_store_popup",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.messageText) }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.assignArticle(_vm.labelsToAssign[0])
                        }
                      }
                    },
                    [_vm._v("Yes")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.assigndialog = false
                        }
                      }
                    },
                    [_vm._v("No")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }