var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-product" }),
        _vm._v(_vm._s(_vm.$t("Product Info")) + " ")
      ]),
      _vm.CUSTOMER === "METRO"
        ? _c("div", [
            _c(
              "section",
              { staticClass: "searchArea max-80" },
              [
                _c("v-select", {
                  staticClass: "form-select ml-3",
                  staticStyle: { width: "150px" },
                  attrs: {
                    items: _vm.SearchItems,
                    label: _vm.$t("Search"),
                    placeholder: _vm.$t("Select Search"),
                    clearable: "",
                    outlined: "",
                    dense: "",
                    "persistent-hint": "",
                    "hide-details": ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.changeRoute(_vm.inputkey)
                    }
                  },
                  model: {
                    value: _vm.inputkey,
                    callback: function($$v) {
                      _vm.inputkey = $$v
                    },
                    expression: "inputkey"
                  }
                }),
                _c("v-text-field", {
                  staticClass: "form-input ml-3",
                  attrs: {
                    label: _vm.$t("Input"),
                    placeholder: _vm.$t("Input Value"),
                    outlined: "",
                    dense: "",
                    "hide-details": "",
                    clearable: ""
                  },
                  model: {
                    value: _vm.inputValue,
                    callback: function($$v) {
                      _vm.inputValue = $$v
                    },
                    expression: "inputValue"
                  }
                }),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn type-search ml-3",
                    attrs: { text: "" },
                    on: { click: _vm.searchProductMetro }
                  },
                  [_vm._v(_vm._s(_vm.$t("Search")))]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.CUSTOMER !== "METRO"
        ? _c("div", [
            _c(
              "section",
              { staticClass: "searchArea max-80" },
              [
                _c(
                  "div",
                  {
                    staticClass: "form-group form-inline",
                    style: { width: "23%" }
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "form-input ml-3",
                      attrs: {
                        label: _vm.$t("Product Description"),
                        placeholder: _vm.$t("Input product description"),
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        clearable: ""
                      },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchProduct($event)
                        }
                      },
                      model: {
                        value: _vm.searchBy.articleName,
                        callback: function($$v) {
                          _vm.$set(_vm.searchBy, "articleName", $$v)
                        },
                        expression: "searchBy.articleName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "form-group form-inline",
                    style: { width: "23%" }
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "form-input ml-3",
                      attrs: {
                        label: _vm.$t("Product ID"),
                        placeholder: _vm.$t("Input product ID"),
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        clearable: ""
                      },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchProduct()
                        }
                      },
                      model: {
                        value: _vm.searchBy.articleId,
                        callback: function($$v) {
                          _vm.$set(_vm.searchBy, "articleId", $$v)
                        },
                        expression: "searchBy.articleId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn type-search ml-3",
                    attrs: { text: "" },
                    on: { click: _vm.searchProduct }
                  },
                  [_vm._v(_vm._s(_vm.$t("Search")))]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.CUSTOMER !== "METRO"
        ? _c(
            "v-data-table",
            {
              staticClass: "tbl-type01 mt-10 products",
              attrs: {
                "item-key": "articleId",
                headers: _vm.productHeaders,
                items: _vm.products,
                "item-class": _vm.getRowClass,
                options: _vm.options,
                "server-items-length": _vm.totalProducts,
                "single-select": false,
                "hide-default-footer": true,
                "show-select": ""
              },
              on: {
                "click:row": _vm.showProductDetail,
                "update:options": function($event) {
                  _vm.options = $event
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item.assignLCD",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.getAssignLCD(item.articleId)) + " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.articleName",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("start_and_end")(item.articleName)) +
                            " "
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [
              _c("template", { slot: "no-data" }, [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                ])
              ])
            ],
            2
          )
        : _vm._e(),
      _vm.CUSTOMER === "METRO"
        ? _c(
            "v-data-table",
            {
              staticClass: "tbl-type01 mt-10 products",
              attrs: {
                "item-key": "articleId",
                headers: _vm.productHeadersMetro,
                items: _vm.products,
                "item-class": _vm.getRowClass,
                options: _vm.options,
                "server-items-length": _vm.totalProducts,
                "single-select": false,
                "hide-default-footer": true,
                "show-select": ""
              },
              on: {
                "click:row": _vm.showProductDetail,
                "update:options": function($event) {
                  _vm.options = $event
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item.articleName",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("start_and_end")(item.articleName)) +
                            " "
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [
              _c("template", { slot: "no-data" }, [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                ])
              ])
            ],
            2
          )
        : _vm._e(),
      _c("ProductDetailDailog", {
        attrs: {
          pdHeaders: _vm.pdHeaders,
          pdItems: _vm.pdItems,
          page: _vm.page,
          store: _vm.store
        },
        on: { fireResetProduct: _vm.resetProduct }
      }),
      _c("div", { staticClass: "table-options" }, [
        _c(
          "div",
          [
            _c("add-product", {
              attrs: {
                user: _vm.user,
                btnDisabledAddProduct: _vm.addBtnDisabled
              },
              on: { fireResetProduct: _vm.resetProduct }
            }),
            _c(
              "v-btn",
              {
                staticClass: "btn ml-2",
                attrs: { disabled: _vm.btnDisabledDelete, text: "" },
                on: { click: _vm.deleteProduct }
              },
              [_vm._v(_vm._s(_vm.$t("Delete")) + " ")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "btn ml-2",
                attrs: { disabled: _vm.exportDisabled, text: "" },
                on: { click: _vm.exportArticleList }
              },
              [_vm._v(_vm._s(_vm.$t("Export")) + " ")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "pageInfo" }, [
          _vm._v(_vm._s(_vm.pageInfoText))
        ]),
        _c(
          "div",
          [
            _c("v-pagination", {
              staticClass: "pageAlign",
              attrs: {
                length: _vm.totalPages * 1,
                "total-visible": 7,
                color: "#2f3b4c"
              },
              on: { input: _vm.paging },
              model: {
                value: _vm.page,
                callback: function($$v) {
                  _vm.page = $$v
                },
                expression: "page"
              }
            })
          ],
          1
        )
      ]),
      _c("a", { ref: "link", style: { display: "none" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }