var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", persistent: "", width: "600" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn ml-2",
                      attrs: { disabled: _vm.btnDisabledAddProduct, text: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("Add")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.addProductVisible,
        callback: function($$v) {
          _vm.addProductVisible = $$v
        },
        expression: "addProductVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup" },
        [
          _c(
            "div",
            { staticClass: "popup-titbox _between" },
            [
              _c("v-card-title", [
                _c("h3", { staticClass: "page-title-bar" }, [
                  _c("i", { staticClass: "ico ico-product" }),
                  _vm._v(_vm._s(_vm.$t("ADD Product")) + " ")
                ])
              ])
            ],
            1
          ),
          _c("v-card-text", [
            _c("div", { staticClass: "product_detail" }, [
              _c(
                "table",
                [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "120px" } }),
                    _c("col")
                  ]),
                  _vm._l(_vm.articleDataList, function(articleData, idx) {
                    return _c("tr", { key: idx }, [
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.addArticleHeader(articleData.key, idx))
                        )
                      ]),
                      _c(
                        "td",
                        [
                          _c("v-text-field", {
                            staticClass: "form-input rounded-0",
                            attrs: {
                              placeholder: _vm.getPlaceholder(articleData.key),
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              solo: "",
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: articleData.value,
                              callback: function($$v) {
                                _vm.$set(articleData, "value", $$v)
                              },
                              expression: "articleData.value"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  })
                ],
                2
              )
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.handleSaveClick }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.cancelAddProduct }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }