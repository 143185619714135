<template>
  <div>
    <h2 class="page-title-bar">
      <i class="ico ico-product"></i>{{ $t('Product Info') }}
    </h2>
    <!-- Search bar -->
    <!-- Metro -->
    <div v-if="CUSTOMER==='METRO'">
      <section class="searchArea max-80">
      <v-select
        v-model="inputkey"
        :items="SearchItems"
        :label="$t('Search')"
        :placeholder="$t('Select Search')"
        class="form-select ml-3"
        clearable
        outlined
        dense
        persistent-hint
        hide-details
        style="width:150px"
        @change="changeRoute(inputkey)"
      ></v-select>
      <v-text-field
        v-model="inputValue"
        :label="$t('Input')"
        :placeholder="$t('Input Value')"
        class="form-input ml-3"
        outlined
        dense
        hide-details
        clearable
      ></v-text-field>
      <v-btn @click="searchProductMetro" class="btn type-search ml-3" text>{{
        $t('Search')
      }}</v-btn>
    </section>
    </div>

  <!-- End Metro -->
  <div v-if="CUSTOMER !=='METRO'">
    <section class="searchArea max-80">
      <div class="form-group form-inline" :style="{ width: '23%' }">
        <v-text-field
          v-model="searchBy.articleName"
          @keyup.enter="searchProduct"
          :label="$t('Product Description')"
          :placeholder="$t('Input product description')"
          class="form-input ml-3"
          outlined
          dense
          hide-details
          clearable
        ></v-text-field>
      </div>

      <div class="form-group form-inline" :style="{ width: '23%' }">
        <v-text-field
          v-model="searchBy.articleId"
          @keyup.enter="searchProduct()"
          :label="$t('Product ID')"
          :placeholder="$t('Input product ID')"
          class="form-input ml-3"
          outlined
          dense
          hide-details
          clearable
        ></v-text-field>
        </div>
      <v-btn @click="searchProduct" class="btn type-search ml-3" text>{{
        $t('Search')
      }}</v-btn>
    </section>
    </div>
    <!-- Product list -->
    <v-data-table
      v-model="selected"
      v-if="CUSTOMER !=='METRO'"
      @click:row="showProductDetail"
      item-key="articleId"
      :headers="productHeaders"
      :items="products"
      :item-class="getRowClass"
      :options.sync="options"
      :server-items-length="totalProducts"
      :single-select="false"
      :hide-default-footer="true"
      show-select
      class="tbl-type01 mt-10 products"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.assignLCD="{ item }">
        {{ getAssignLCD(item.articleId) }}
      </template>
      <template slot="no-data">
        <p>
          {{ $t('No data available') }}
        </p>
      </template>
      <template v-slot:[`item.articleName`]="{ item }">
              {{item.articleName | start_and_end}}
            </template>
    </v-data-table>

    <!-- //Metro -->
    <v-data-table
      v-model="selected"
      v-if="CUSTOMER ==='METRO'"
      @click:row="showProductDetail"
      item-key="articleId"
      :headers="productHeadersMetro"
      :items="products"
      :item-class="getRowClass"
      :options.sync="options"
      :server-items-length="totalProducts"
      :single-select="false"
      :hide-default-footer="true"
      show-select
      class="tbl-type01 mt-10 products"
    >
      <template slot="no-data">
        <p>
          {{ $t('No data available') }}
        </p>
      </template>
      <template v-slot:[`item.articleName`]="{ item }">
              {{item.articleName | start_and_end}}
            </template>
    </v-data-table>

    <!-- End Metro -->

    <!-- Product 상세정보 팝업창 -->
    <ProductDetailDailog
      @fireResetProduct="resetProduct"
      :pdHeaders="pdHeaders"
      :pdItems="pdItems"
      :page="page"
      :store="store"
    />
    <!-- Button group -->
    <div class="table-options">
      <div>
        <add-product
          :user="user"
          :btnDisabledAddProduct="addBtnDisabled"
          @fireResetProduct="resetProduct"
        />
        <v-btn
          :disabled="btnDisabledDelete"
          @click="deleteProduct"
          class="btn ml-2"
          text
          >{{ $t('Delete') }}
        </v-btn>

        <v-btn
          :disabled="exportDisabled"
          @click="exportArticleList"
          class="btn ml-2"
          text
          >{{ $t('Export') }}
        </v-btn>
      </div>
      <!-- Pagination -->

      <div class="pageInfo">{{ pageInfoText }}</div>
      <div>
      <v-pagination
          v-model="page"
          @input="paging"
          :length="totalPages * 1"
          :total-visible="7"
          color="#2f3b4c"
          class="pageAlign"
        ></v-pagination>
      </div>
    </div>
    <!-- Export 기능 링크 -->
    <a ref="link" :style="{ display: 'none' }" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// Utility
import EventBus from '@/plugins/eventBus'
import { exportFiles } from '@/plugins/exporter'
import { setTempObj, getTempObj } from '@/plugins/sessionStorageManager'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import dicamoApi from '@/lcd/plugins/dicamoApi'
import configs from '@/plugins/configs'
// Components
import AddProduct from './modal/AddProduct.vue'
import ProductDetailDailog from './modal/ProductDetailDialog.vue'
// Mixins
import Pagenation from '@/mixins/Pagenation'
export default {
  name: 'ProductList',
  mixins: [Pagenation],
  components: {
    AddProduct,
    ProductDetailDailog
  },
  data () {
    return {
      productList: [],
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      btnShowProductDetail: false,
      btnDisabledAddDelete: false,
      btnDisabledBlinking: false,
      btnDisabledExport: false,
      btnDisabledFileUpload: false,
      user: null,
      // Product List
      requestConfig: {},
      selected: [],
      pdSelected: [],
      page: 1,
      totalPages: 1,
      pageInfoText: '',
      totalVisiblePages: 5,
      options: {},
      totalProducts: null,
      // Product Detail
      productDetailPopedUp: false,
      products: [],
      pdHeaders: [
        { text: '#', sortable: false, align: 'center', value: 'no' },
        {
          text: this.$t('Update Date'),
          sortable: false,
          align: 'center',
          value: 'date'
        }
      ],
      pdItems: [],
      searchBy: { articleName: null, articleId: null },
      searchParam: {
        name: null,
        id: null

      },
      // Metro
      searchMetroParam: {
        name: null,
        id: null,
        key: null,
        value: null

      },
      CUSTOMER: configs.Customer,
      inputkey: '',
      inputValue: '',
      changeflag: false,
      requests: {
        getReservedSearchItems: {
          method: 'get',
          url: '/api/common/articles/content'
        }
      },
      defaultHeader1: [
        { text: this.$t('PRODUCT DESCRIPTION'), value: 'articleName', width: '20%' },
        { text: this.$t('PRODUCT ID'), value: 'articleId', width: '17%' },
        { text: this.$t('NFC URL'), value: 'nfcUrl', width: '17%' }
      ],
      defaultHeader2: [{ text: this.$t('ASSIGNED LABEL'), value: 'assignedLabel', width: '10%', sortable: false },
        { text: this.$t('LAST UPDATE TIME'), value: 'lastUpdated', width: '25%' }],
      defaultsearchItems: [
        { text: this.$t('PRODUCT DESCRIPTION'), value: 'articleName' },
        { text: this.$t('PRODUCT ID'), value: 'articleId' }
      ]
      //
    }
  },
  computed: {
    productHeaders () {
      return [
        { text: this.$t('PRODUCT DESCRIPTION'), value: 'articleName', width: '20%' },
        { text: this.$t('PRODUCT ID'), value: 'articleId', width: '20%' },
        { text: this.$t('ASSIGNED LCD'), value: 'assignLCD', sortable: false, width: '30%' },
        { text: this.$t('LAST UPDATE TIME'), value: 'lastUpdated', width: '20%' }
      ]
    },
    // Metro
    productHeadersMetro () {
      var data = JSON.parse(localStorage.getItem('reservedItems'))
      console.log(data)
      var array = []
      if (data.reservedList !== undefined) {
        if (data.reservedList.reservedFour) {
          array.push({ text: this.$t('RESERVERD_FOUR') + ' ' + '(' + (data.reservedList.reservedFour) + ')', value: 'reservedFour', sortable: false, width: '5%' })
        }
        if (data.reservedList.reservedFive) {
          array.push({ text: this.$t('RESERVERD_FIVE') + ' ' + '(' + (data.reservedList.reservedFive) + ')', value: 'reservedFive', sortable: false, width: '5%' })
        }
        if (data.reservedList.reservedSix) {
          array.push({ text: this.$t('RESERVERD_SIX') + ' ' + '(' + (data.reservedList.reservedSix) + ')', value: 'reservedSix', sortable: false, width: '5%' })
        }
        return [...this.defaultHeader1, ...array, ...this.defaultHeader2]
      } else {
        return [...this.defaultHeader1, ...this.defaultHeader2]
      }
    },

    SearchItems () {
      var data = JSON.parse(localStorage.getItem('reservedItems'))
      console.log(data.reservedList)
      var obj = []
      if (data.reservedList !== undefined) {
        if (data.reservedList.reservedFour) {
          obj.push({ text: this.$t(data.reservedList.reservedFour), value: data.reservedList.reservedFour })
        }
        if (data.reservedList.reservedFive) {
          obj.push({ text: this.$t(data.reservedList.reservedFive), value: data.reservedList.reservedFive })
        }
        if (data.reservedList.reservedSix) {
          obj.push({ text: this.$t(data.reservedList.reservedSix), value: data.reservedList.reservedSix })
        }
        return [...this.defaultsearchItems, ...obj]
      } else {
        return [...this.defaultsearchItems]
      }
    },

    // End Metro
    exportDisabled () {
      let btnDisabled = this.btnDisabledExport
      if (!btnDisabled) { btnDisabled = this.products !== undefined && this.products.length < 1 }
      return btnDisabled
    },
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    btnDisabledDelete () {
      return this.btnDisabledAddDelete || this.selected.length < 1
    },
    addBtnDisabled () {
      return this.btnDisabledAddDelete || !this.store.code
    }
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    getArticleSortKey (sortKey) {
      switch (sortKey) {
        case 'articleId':
          sortKey = 'id.articleId'
          break
        case 'articleName':
          sortKey = 'name'
          break
        case 'lastUpdated':
          sortKey = 'lastModified'
          break
        default:
          break
      }
      return sortKey
    },
    getAssignLCD (articleId) {
      let assignLCD = ''

      let count = []

      this.productList.forEach(item => {
        if (articleId === item.productId) {
          item.lcd.forEach(lcd => {
            count.push(lcd.mac)
          })
          count = [...new Set(count)]
          assignLCD = count.length + ' EA'
        }
      })

      return assignLCD
    },
    paging (page = this.page) {
      if (this.CUSTOMER === 'METRO') {
        if (this.inputkey !== '' && this.inputValue !== '' && this.inputkey !== undefined && this.inputValue !== null) {
          if (this.searchMetroParam.name !== null || this.searchMetroParam.id !== null) {
            this.changeflag = false
            this.page = page
            this.goToPageInput = page
            this.getProduct(page)
          }
          if (this.searchMetroParam.key !== 'articleName' && this.searchMetroParam.key !== 'articleId' && this.searchMetroParam.key !== null) {
            this.changeflag = true
            this.page = page
            this.goToPageInput = page
            this.getProduct(page)
          } else {
            this.changeflag = false
            this.page = page
            this.goToPageInput = page
            this.getProduct(page)
          }
        } else {
          this.changeflag = false
          this.page = page
          this.goToPageInput = page

          this.getProduct(page)
        }
      } else {
        if (this.searchBy.articleName === '' || this.searchBy.articleName === null) {
          this.searchParam.name = null
        }
        if (this.searchBy.articleId === '' || this.searchBy.articleId === null) {
          this.searchParam.id = null
        }
        this.changeflag = false
        this.page = page
        this.goToPageInput = page
        this.getProduct(page)
      }
    },
    searchProduct () {
      if (this.CUSTOMER === 'METRO') {

      } else {
        this.searchParam.name = this.searchBy.articleName
        this.searchParam.id = this.searchBy.articleId
      }
      this.getProduct(1)
      this.page = 1
    },
    resetProduct () {
      this.getProduct(1)
      this.page = 1
      this.selected = []
      // For Metro
      this.changeflag = false
    },
    getRowClass () {
      return codes.ROW_CLASS
    },
    exportArticleList () {
      if (this.products.length < 1) {
        EventBus.$emit('messageAlert', this.$t('No data to export.'))
        return
      }
      exportFiles(this.requestConfig, this.$refs.link, 'ProductList.xlsx')
    },
    convertProductsDate (articleList) {
      return articleList.map(product => {
        product.lastUpdated = commons.convertDate(product.lastUpdated)
        // Metro
        if (this.CUSTOMER === 'METRO') {
          var data = JSON.parse(localStorage.getItem('reservedItems'))
          if (data.reservedList !== undefined) {
            if (product.reserved) {
              if (data.reservedList.reservedFour) {
                product.reservedFour = product.reserved[data.reservedList.reservedFour]
              }
              if (data.reservedList.reservedFive) {
                product.reservedFive = product.reserved[data.reservedList.reservedFive]
              }
              if (data.reservedList.reservedSix) {
                product.reservedSix = product.reserved[data.reservedList.reservedSix]
              }
            }
          }
        }
        return product
      })
    },

    getProduct (page) {
      var params = this.buildParmas()
      if (commons.isNull(params.store)) return
      if (page !== null) params.page = page - 1
      const config = { params: params }
      if (this.productionType === codes.productionTypes.CLOUD) { params.company = this.$store.state.auth.user.company }
      // For LCD
      params.size = this.limit
      this.$utils
        .callAxios(
          codes.requests.getArticles.method,
          codes.requests.getArticles.url,
          config
        )
        .then(res => {
          this.saveRequestConfig(res.config)
          this.products = this.convertProductsDate(res.data.articleList)
          this.totalProducts = this.products.length
          this.pageInfoText = commons.getPageInfoText(res.headers)
          this.totalPages = Number(res.headers['x-totalpages'])
          this.selected = []
          this.getAssignedLCD()
        })
        .catch(error => {
          this.products = []
          this.totalProducts = this.products.length
          const headers = {}
          headers['x-totalelements'] = 0
          this.pageInfoText = commons.getPageInfoText(headers)
          this.totalPages = 0
          this.selected = []
          console.debug(`Could not find any products. error: ${error}`)
        })
      // For Metro
      if (this.changeflag) {
        config.params.data = true
        // config.params.export = false
        config.params.company = this.$store.state.auth.user.company
        this.$utils
          .callAxios(
            this.requests.getReservedSearchItems.method,
            this.requests.getReservedSearchItems.url,
            config
          )
          .then(res => {
            this.saveRequestConfig(res.config)
            this.products = this.convertProductsDate(res.data.articleList)
            this.totalProducts = this.products.length
            this.pageInfoText = commons.getPageInfoText(res.headers)
            this.totalPages = Number(res.headers['x-totalpages'])
            this.selected = []
            this.changeflag = false
          })
          .catch(error => {
            this.products = []
            this.totalProducts = this.products.length
            const headers = {}
            headers['x-totalelements'] = 0
            this.pageInfoText = commons.getPageInfoText(headers)
            this.totalPages = 0
            this.selected = []
            console.debug(`Could not find any products. error: ${error}`)
            this.changeflag = false
          })
      } else {
        this.$utils
          .callAxios(
            codes.requests.getArticles.method,
            codes.requests.getArticles.url,
            config
          )
          .then(res => {
            this.saveRequestConfig(res.config)
            this.products = this.convertProductsDate(res.data.articleList)
            this.totalProducts = this.products.length
            this.pageInfoText = commons.getPageInfoText(res.headers)
            this.totalPages = Number(res.headers['x-totalpages'])
            this.selected = []
            this.changeflag = false
          })
          .catch(error => {
            this.products = []
            this.totalProducts = this.products.length
            const headers = {}
            headers['x-totalelements'] = 0
            this.pageInfoText = commons.getPageInfoText(headers)
            this.totalPages = 0
            this.selected = []
            console.debug(`Could not find any products. error: ${error}`)
            this.changeflag = false
          })
      }
    },

    // Product Detail 팝업의 좌측 메뉴를 채우는 데이터.
    // Server 버전의 경우 아직 선택한 1건의 Product 에 대해서만 데이터를 표출한다.
    getPdItems (item) {
      const pdItems = []
      const pdItem = {
        no: '1',
        date: item.lastUpdated
      }
      pdItems.push(pdItem)
      return pdItems
    },
    async showProductDetail (item) {
      if (this.btnShowProductDetail) return
      this.pdItems = this.getPdItems(item)
      EventBus.$emit('showCurrArticleDataValues', item)
      EventBus.$emit('showProductDetail')
    },
    saveRequestConfig (config) {
      const requestConfig = {
        url: config.url,
        method: config.method,
        params: config.params,
        PopUpMessage: 'true'
      }
      this.requestConfig = requestConfig
    },
    buildParmas () {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      var params = { store: this.store.code }
      // For Metro
      if (this.CUSTOMER === 'METRO') {
        if (this.inputkey === 'articleName') {
          params.name = this.searchMetroParam.name
        }
        if (this.inputkey === 'articleId') {
          params.id = this.searchMetroParam.id
        }
        if (this.inputkey !== 'articleName' && this.inputkey !== 'articleId') {
          params.key = this.searchMetroParam.key
          params.value = this.searchMetroParam.value
        }
        let sort = null
        if (commons.isSorting(sortBy, sortDesc)) {
          const sortKey = this.getArticleSortKey(sortBy[0])
          const sortOrder = sortDesc[0]
          sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
        }
        if (sort !== null) params.sort = sort
        if (itemsPerPage > 0) params.size = itemsPerPage

        // End Metro
      } else {
        if (commons.isValidStr(this.searchParam.name)) { params.name = this.searchParam.name }
        if (commons.isValidStr(this.searchParam.id)) { params.id = this.searchParam.id }
        let sort = null
        if (commons.isSorting(sortBy, sortDesc)) {
          const sortKey = this.getArticleSortKey(sortBy[0])
          const sortOrder = sortDesc[0]
          sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
        }
        if (sort !== null) params.sort = sort
        if (itemsPerPage > 0) params.size = itemsPerPage
      }
      return params
    },

    // Metro
    changeRoute (data) {
      this.searchMetroParam.key = null
      this.searchMetroParam.value = null
      this.changeflag = false
      if (data !== 'articleName' && data !== 'articleId') {
        this.changeflag = true
      }
    },

    searchProductMetro () {
      console.log(this.inputkey)
      console.log(this.inputValue)
      this.changeRoute(this.inputkey)
      if (this.inputkey === '') {
        EventBus.$emit('messageAlert', this.$t('Enter data to search.'))
      }
      if (this.inputValue === '') {
        EventBus.$emit('messageAlert', this.$t('Enter data to search.'))
      }
      if (this.inputkey !== '' && this.inputValue !== '') {
        if (this.inputValue !== null) {
          if (this.inputkey === 'articleName') {
            this.searchMetroParam.name = this.inputValue
          }
          if (this.inputkey === 'articleId') {
            this.searchMetroParam.id = this.inputValue
          }
          if (this.inputkey !== 'articleName' && this.inputkey !== 'articleId') {
            this.searchMetroParam.key = this.inputkey
            this.searchMetroParam.value = this.inputValue
          }

          this.getProduct(1)
          this.page = 1
        }
      }
      if (this.inputkey === undefined && this.inputValue === null) {
        this.changeflag = false
        this.getProduct(1)
        this.page = 1
      }
    },

    // End Metro
    resetConditions () {
      this.searchBy.articleName = ''
      this.searchBy.articleId = ''
      this.options = {
        sortBy: [],
        sortDesc: []
      }
      this.selected = []
    },
    // saveRequestConfig (config) {
    //   this.requestConfig = getRequestConfig(config)
    // },

    getAssignedLCD () {
      const productIdxs = []
      this.products.forEach(item => {
        productIdxs.push(item.articleId)
      })

      const form = new FormData()

      form.append('company', this.$store.state.auth.user.company)
      form.append('store', this.$store.state.dataStore.selectedStore.code)
      form.append('type', 'product')
      form.append('product_ids', JSON.stringify(productIdxs))

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getTemplateList.method,
          dicamoApi.requests.getTemplateList.url,
          form
        )
        .then(res => {
          this.productList = res.data.data
          // this.mediaDataList = this.convertProductsDate(res.data.articleList)
          // this.totalProducts = this.products.length
          // this.pageInfoText = commons.getPageInfoText(res.headers)
          // this.totalPages = Number(res.headers['x-totalpages'])
          // this.selected = []
        })
        .catch(error => {
          this.productList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any medias. error: ${error}`)
        })
    },
    deleteProduct () {
      const data = { articleDeleteList: [] }
      const params = { store: this.store.code }
      data.articleDeleteList = this.selected.map(article => article.articleId)
      const config = { data, params }
      if (this.productionType === 'cloud') { params.company = this.$store.state.auth.user.company }
      this.$utils
        .callAxios(
          codes.requests.deleteArticle.method,
          codes.requests.deleteArticle.url,
          config
        )
        .then(res => {
          const result = res.data
          EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          this.resetProduct()
        })
        .catch(error => {
          console.debug(`Failed to delete products. error: ${error}`)
        })
    },
    setDiabledBtns () {
      // Product List : Detail Popup
      this.$store.dispatch('auth/getDisabledBtn', '23200').then(flag => {
        this.btnShowProductDetail = flag
      })
      // // Product List : Add / Update / Delete
      this.$store.dispatch('auth/getDisabledBtn', '23201').then(flag => {
        this.btnDisabledAddDelete = flag
      })
      // // Product List : Export
      this.$store.dispatch('auth/getDisabledBtn', '23202').then(flag => {
        this.btnDisabledExport = flag
      })
    }
  },
  created () {
    this.user = this.$store.state.auth.user
  },
  mounted () {
    this.clearTabindex()
    this.setDiabledBtns()
    EventBus.$emit('enableSelectedStores', true)
    const productListSessionData = getTempObj('productList')
    if (
      !commons.isNull(productListSessionData) &&
      !commons.isNull(productListSessionData.searchBy)
    ) {
      this.searchBy = productListSessionData.searchBy
    }
    if (!this.store.code) {
      EventBus.$emit('openSelectedStores')
    }
  },
  watch: {
    options: {
      handler () {
        this.getProduct(this.page)
        // this.page = 1
      },
      deep: true
    },
    store: {
      handler () {
        this.getProduct(1)
        this.page = 1
      }
    },
    searchBy: {
      handler (newSearchBy) {
        const productList = {
          searchBy: newSearchBy
        }
        setTempObj('productList', productList)
      },
      deep: true
    }
  },
  filters: {
    start_and_end (str) {
      if (str) {
        if (str.length > 15) {
          return str.substr(0, 8) + ' ..... ' + str.substr(str.length - 5, str.length)
        }
        return str
      }
    }
  }
}
</script>

<style scoped>
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    padding-left: 10px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
.form-add-btn {
  width: calc(100% - 110px);
  border: 1px solid rgba(0, 0, 0, 0.38);
  overflow: hidden;
}
.form-add-btn .v-list {
  width: 100%;
  max-height: 100px;
}
.form-add-btn .v-list .v-list-item {
  min-height: auto;
  padding: 0 5px;
}
.form-add-btn .v-list .v-list-item:before {
  background-color: #001e38;
}
.form-add-btn .v-list .v-list-item + .v-list-item {
  border-top: 1px solid rgba(0, 0, 0, 0.38);
}
.pageAlign{
  float:right;

}
.wraptext{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  display: block;

}
</style>
