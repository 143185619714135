import { render, staticRenderFns } from "./Product.vue?vue&type=template&id=7102b1d4&scoped=true&"
import script from "./Product.vue?vue&type=script&lang=js&"
export * from "./Product.vue?vue&type=script&lang=js&"
import style0 from "./Product.vue?vue&type=style&index=0&id=7102b1d4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7102b1d4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VDataTable,VPagination,VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/-36BVGaF/0/applicationg/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7102b1d4')) {
      api.createRecord('7102b1d4', component.options)
    } else {
      api.reload('7102b1d4', component.options)
    }
    module.hot.accept("./Product.vue?vue&type=template&id=7102b1d4&scoped=true&", function () {
      api.rerender('7102b1d4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lcd/views/Contents/Product.vue"
export default component.exports